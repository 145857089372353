import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ClerkLayout from "./components/ClerkLayout";
import Footer from "./components/Footer";
import Header from "./components/Header";

/**
 * This is the primary application returned. It will supply the Clerk provider
 * configuration, via publishable key. The header component provides the LOU branding
 * elements for the header. The footer provides the required links, in addition, a deeplink
 * generated by root of the environment for the Quote page, apart of the Mendix environment.
*/

// Static array of environment details used for Clerk configuration and redirect information.
const envDetails = [
  {
    "cf":"https://loulogin.evosus.com",
    "pk": process.env.REACT_APP_LOU,
    "src":"https://lou.evosus.com"
  },
  {
    "cf":"https://louloginp.evosus.com",
    "pk": process.env.REACT_APP_LOU,
    "src":"https://loup.evosus.com"
  },
  {
    "cf":"https://loulogin-test.evosus.com",
    "pk": process.env.REACT_APP_LOUTEST,
    "src":"https://lou-test.evosus.com" 
  },
  { 
    "cf":"https://loulogin-accp.evosus.com",
    "pk": process.env.REACT_APP_LOUACCP,
    "src":"https://lou-accp.evosus.com"
  },  
  {    
    "cf":"https://loulogin-demo.evosus.com",
    "pk": process.env.REACT_APP_LOUDEMO,
    "src":"https://lou-demo.evosus.com"  
  },
  {
    "cf":"https://loulogin-dev.evosus.com",
    "pk": process.env.REACT_APP_LOUDEV,
    "src":"https://lou-dev.evosus.com"
  },
  {
    "cf":"http://127.0.0.1:8787",
    "pk": process.env.REACT_APP_LOULOGIN,
    "src":"http://localhost:8080"
  }
];

// Get the envConfig and set it.
const envConfig = envDetails.find((env)=>env.cf === window.location.origin);

const App = () => {
    // Use effects to store redirect url. If param, that is used, else api call is used.  
    const [params] = useSearchParams();
    const [redirect, setRedirect] = useState("");

    // On load execute this one time to get and set the redirect
    useEffect(() => {
      // Check for redirect existing
      const redir = params.get("redirect_url") ?? "";

      // Check for satellite redirect existing
      let satellite = params.get("__clerk_satellite_url") ?? "";

      let abortCtrl = null;

      if (!satellite && !redir) {
          // If the fetch is interupted, abort controller will catch it.
          abortCtrl = new AbortController();

          // Async API call to get redirect url from LOU.
          const getRedirect = async() => {
            const res = await fetch(envConfig.src + "/oauth/v2/authattempt", {
              signal: abortCtrl.signal
            });
            if (!abortCtrl.signal.aborted) {
              const url = await res.text();
              setRedirect(url);
            }
          };
          getRedirect();         
      }

      const uri = satellite ? satellite += "oauth/v2/callback" : redir;
      setRedirect(uri);

      // Returns on unmount
      return () => {
        if (abortCtrl) abortCtrl.abort(); 
      }
  }, [params]);

  // Return the application.
  return ( 
    <>
      <Header />
      <h2 className="ev-welcome">Welcome</h2>
      <div className="ev-clerk-wrapper">
        <ClerkLayout pk={envConfig.pk} redirect={redirect}/> 
      </div>
      <Footer quoteUrl= {envConfig.src + "/link/NewQuote"} />
    </>
  );
}

export default App;
